export default {
    id: null,
    code: null,
    name: null,
    subject: null,
    description: null,
    document: '',
    documentPreview: '',
    internal: null,
    hits: null,
    categoria: null,
    public: false,
    active: true,
    receberAtualizacoes: true,
    order: 0,
    config: {
        bind: null,
        entities: [],
        formatos: [],
    }
}
